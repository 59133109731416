<template>
  <div class="reports">
    <div class="card-header">
      <i class="el-icon-edit"></i>
      <span>Reports</span>
    </div>
    <div class="card-body">
      <el-button type="primary"
                 class="request"
                 @click="$router.push('/A_reportDetail')">Reports</el-button>
      <el-form :label-position="labelPosition"
               label-width="80px"
               :model="formLabelAlign"
               :inline="true">
        <el-form-item label="Product Name:">
          <el-input v-model="formLabelAlign.name"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="Status:">
          <el-select v-model="value"
                     clearable
                     placeholder="Please select">
            <el-option v-for="item in options"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Create Date Begin:">
          <!-- 添加时间格式 -->
          <el-date-picker v-model="valueBegin"
                          type="date"></el-date-picker>
        </el-form-item>
        <el-form-item label="Create Date End:">
          <el-date-picker v-model="valueEnd"
                          type="date"></el-date-picker>
        </el-form-item>
        <el-button type="primary"
                   class="search"
                   icon="el-icon-search"
                   @click="searchReport()">Search</el-button>
      </el-form>
    </div>
    <div class="repList">
      <!-- 商品栏 -->
      <div class="requestBox"
           v-for="(item, index) in reqList"
           :key="index">
         <div style="margin-right: 30px">
            <!-- <div class="reqText" >
              <span>Product name：</span>
              {{item.product_name}}
            </div> -->

            <div class="reqText">
              <!-- <span>Product img:</span> -->
             <img :src="item.product_image_url"
                                         alt="" style="margin: 0px 0px;width: 100px; height: 100px;">
            </div>
         </div>
        <div class="reqLeft">
         <div class="reqText">
                    {{item.product_name}}
         </div>

          <div class="reqText">
            <span>Order Number：</span>
            {{item.order_number}}
          </div>
          <div class="reqText">
            <span>Status：</span>
            {{item.status}}
          </div>
          <div class="reqText">
            {{item.create_date | formatDate}}
          </div>
        </div>
        <div class="reqRight">
          <div class="reqText">
            <span>Screenshots：</span>
<!--            <a v-if="item.screenshot_url" target="_blank" id="screenshot_url" >-->
              <el-image style="width: 80px;height: 80px;"
                        :src="item.screenshot_url[0]"
                        :preview-src-list="item.screenshot_url">
              </el-image>
<!--            </a>-->
          </div>
          <div class="reqText">
            <span>Reason：</span>
            {{item.report_reason}}
          </div>
          <div class="reqText">
            <span>Result：</span>
            {{item.admin_reply}}
          </div>
        </div>
      </div>
      <!-- 分页 -->
      <div class="block"
           v-if="reqListBool">
        <div class="blockDiv">
          <el-pagination @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
                         :current-page="currentPage"
                         :page-sizes="[10, 20, 50, 100]"
                         :page-size="currentSize"
                         layout="total,sizes, prev, pager, next, jumper"
                         :total="currentTotal">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 引入接口
import {
  findByUserId // 用户查询投诉信息
} from '../../api/buyerAfter'
// 格式化时间
import { formatDate } from '../../utils/data.js'
export default {
  data () {
    return {
      labelPosition: 'top',
      formLabelAlign: {
        name: ''
      },
      // 状态
      options: [
        {
          value: '0',
          label: 'pending'
        },
        {
          value: '1',
          label: 'solved'
        },
        {
          value: '2',
          label: 'reject'
        }
      ],
      // 开始时间
      valueBegin: '',
      // 结束时间
      valueEnd: '',
      value: '',
      reqListBool: false,
      reqList: [],
      // 分页
      currentPage: 1,
      currentSize: 10,
      currentTotal: 0

    }
  },
  filters: {
    // 时间格式化
    formatDate (time) {
      const date = new Date(time)
      // // (new Date(time))
      return formatDate(date, 'yyyy-MM-dd hh:mm')
    }
  },
  created () {
    this.reportList(this.currentPage, this.currentSize)
  },
  methods: {
    // 投诉列表
    reportList (page, size) {
      this.currentSize = size
      // 用户查询投诉信息
      findByUserId({
        dto: {
          create_date_begin: this.valueBegin,
          create_date_end: this.valueEnd,
          order_number: this.formLabelAlign.name,
          status: this.value
        },
        page: page,
        size: size
      }).then(result => {
        if (result.data.code === 200) {
          console.log(result)
          // this.$message.success(result.data.message)
          this.reqList = result.data.data.rows
          this.currentTotal = result.data.data.total
          this.reqList.map(v => {
            for (const key in this.options) {
              if (v.status === parseInt(key)) {
                v.status = this.options[key].label
                v.screenshot_url = JSON.parse(v.screenshot_url)
              }
            }
          })
          if (this.reqList.length > 0) {
            this.reqListBool = true
          } else {
            this.reqListBool = false
          }
        }
      }).catch(err => {
        return err
      })
    },
    // 查询投诉列表
    searchReport () {
      // (this.value)
      // 用户查询投诉信息
      findByUserId({
        dto: {
          create_date_begin: this.valueBegin,
          create_date_end: this.valueEnd,
          order_number: this.formLabelAlign.name,
          status: this.value
        }
      }).then(result => {
        if (result.data.code === 200) {
          this.$message.success(result.data.message)
          this.reqList = result.data.data.rows
          this.currentTotal = result.data.data.total
          this.reqList.map(v => {
            for (const key in this.options) {
              if (v.status === parseInt(key)) {
                v.status = this.options[key].label
                v.screenshot_url = JSON.parse(v.screenshot_url)
              }
            }
          })
        }
      }).catch(err => {
        return err
      })
    },
    // 分页
    handleSizeChange (val) {
      this.reportList(this.currentPage, val)
    },
    handleCurrentChange (val) {
      this.reportList(val, this.currentSize)
    }
  }
}
</script>

<style lang='less' scoped>
.reports {
  overflow: hidden;
  background: #fff;
  .card-header {
    padding: 0 20px;
    margin-bottom: 0;
    background-color: #f0f3f5;
    border-bottom: 1px solid #c8ced3;
    span {
      margin-left: 10px;
      line-height: 45px;
    }
  }
  .card-body {
    padding: 20px;
    flex: 1 1 auto;
    background-color: #fff;
    .request,
    .search {
      color: #fff;
      background-color: #20a8d8;
      border-color: #20a8d8;
      &:hover {
        color: #fff;
        background-color: #1b8eb7;
        border-color: #1985ac;
      }
    }
    .search {
      margin-top: 40px;
    }
  }
  .repList {
    margin: 20px;
    // 商品栏
    .requestBox {
      margin-top: 15px;
      width: 100%;
      box-sizing: border-box;
      padding: 10px 20px;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      .reqLeft{
        margin-right: 30px;
        width: 30%;
        .reqText {
          margin: 10px 0;
          max-width: 300px;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
          span {
            font-weight: bolder;
          }
        }
      }
      .reqRight {
        width: 40%;
        //margin-right: 100px;
        .reqText {
          margin: 10px 0;
          max-width: 300px;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
          span {
            font-weight: bolder;
          }
          //img {
          //  max-width: 80px;
          //  max-height: 80px;
          //}
        }
      }
    }
  }
}
</style>
<style lang="less">
.el-form--label-top .el-form-item__label {
  padding: 0px !important;
}

#screenshot_url{
          width: 50px;
          height:  40px;
          // margin-left:5px;
          display: inline-block;
          position: absolute;
          // margin-top: -51%;
          .el-image{
              position: absolute;
                  // transform: scale(.6);
              margin-top: -50%;
              img{
                  width: 50px;
                  height: 40px;
              }
          }

      }
</style>
